import { useState } from "react";

import QuetionAnswerActionButtonsContainer from "./QuetionAnswerActionButtonsContainer/QuetionAnswerActionButtonsContainer";

import QuestionsLIst from "./QuestionsLIst/QuestionsLIst";

const QuestionAnswerContainer = ({
  questionsObj,
  onNextClick,
  onPrevClick,
  next,
  onGetGrade,
}) => {
  const { options, correctAns } = questionsObj;
  const [isActiveOption, setIsActiveOption] = useState(false);
  const [grade, setGrade] = useState(false);
  const [list, setLIst] = useState([...options]);

  const getAnswer = (answer) => {
    setGrade(() => answer);
  };
  const getGrade = () => {
    onGetGrade(grade);
    onNextClick();
    setLIst(() => [...options]);
    setIsActiveOption(() => false);
  };
  const resetActive = () => {
    setLIst(() => [...options]);
    setIsActiveOption(() => false);
  };

  const goBack = () => {
    onPrevClick();
    resetActive();
  };

  const getActiveElement = (questionID) => {
    const selectedItemArray = options.map((option, index) => {
      if (option.questId === questionID) {
        return { ...option, isActive: true };
      } else {
        return option;
      }
    });
    setLIst(() => [...selectedItemArray]);
    setIsActiveOption(() => true);
  };

  return (
    <div className="rightContainer">
      <div className="rightContainerInner">
        <QuestionsLIst
          list={list}
          correctAnswer={correctAns}
          isActiveOption={isActiveOption}
          onActiveSelect={getActiveElement}
          onGetAnswer={getAnswer}
        />
      </div>

      <QuetionAnswerActionButtonsContainer
        next={next}
        isActiveOption={isActiveOption}
        onPrevClick={goBack}
        onResetActiveState={resetActive}
        onGetGrade={getGrade}
      />
    </div>
  );
};

export default QuestionAnswerContainer;
