import { questions } from "../../assets/helperData/helperData";

const QuestionsContainer = ({ nextQuestion, questionsArr }) => {
  if (!questionsArr) return;
  return (
    <div className="leftContainer">
      <div className="leftContainerInner">
        <h1>Pitanje:</h1>
        <h3>{questionsArr.question}</h3>
      </div>
      <div className="qNumber">
        {nextQuestion === 40 ? 40 : nextQuestion + 1}/{questions.length}
      </div>
    </div>
  );
};

export default QuestionsContainer;
