import React from "react";
import { ImRadioChecked2, ImRadioUnchecked } from "react-icons/im";

const QuestionsLIst = ({
  list,
  correctAnswer,
  onActiveSelect,
  onGetAnswer,
}) => {
  return (
    <ul className="options">
      {list &&
        list.map(({ question, questId, isActive }, index) => {
          return (
            <li
              key={index}
              className={`${isActive ? "option activeOption" : "option"}`}
              onClick={() => {
                onGetAnswer(correctAnswer === questId);
                // console.log("clicked");
                // nesto(index);
                onActiveSelect(questId);
              }}
            >
              <div className="radioCheckIcon">
                {isActive ? (
                  // <ImRadioUnchecked size={15} />
                  <ImRadioChecked2 size={15} />
                ) : (
                  // <ImRadioChecked2 size={15} />
                  <ImRadioUnchecked size={15} />
                )}
              </div>
              {question}
            </li>
          );
        })}
    </ul>
  );
};

export default QuestionsLIst;
