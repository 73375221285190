import React from "react";
import {
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardDoubleArrowLeft,
} from "react-icons/md";

const QuetionAnswerActionButtonsContainer = ({
  next,
  isActiveOption,
  onPrevClick,

  onResetActiveState,
  onGetGrade,
}) => {
  return (
    <div className="actionButtons">
      <button
        className={`btn ${next <= 0 ? "btn-disable" : null}`}
        onClick={next <= 0 ? null : onPrevClick}
      >
        <MdOutlineKeyboardDoubleArrowLeft size={20} />
        Prethodno
      </button>
      {next === 39 ? (
        <button
          className={`btn btn-primary ${
            isActiveOption === false ? "btn-disable" : ""
          }`}
          onClick={
            isActiveOption === false
              ? null
              : () => {
                  onResetActiveState();
                  onGetGrade();
                }
          }
        >
          Pošalji
        </button>
      ) : (
        <button
          className={`btn btn-primary ${
            isActiveOption === false ? "btn-disable" : ""
          }`}
          onClick={
            isActiveOption === false
              ? null
              : () => {
                  onResetActiveState();
                  onGetGrade();
                }
          }
        >
          Sljedeće
          <MdOutlineKeyboardDoubleArrowRight size={20} />
        </button>
      )}
    </div>
  );
};

export default QuetionAnswerActionButtonsContainer;
