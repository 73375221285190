// export const questions = [
//   {
//     id: 1,
//     question:
//       "Kompanija u kojoj radim postavlja finansijsko-strateške ciljeve rasta i razvoja.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 2,
//     question:
//       "Dijeljenje informacija u kompaniji u kojoj radim je pravovremeno i jasno.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 3,
//     question:
//       "Kompanija u kojoj radim može da ispuni sve zahtjeve njenog tržišta.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 4,
//     question:
//       "Ciljevi i radni zadaci, koji su postavljeni zaposlenicima, jasno su definisani i razumljivi.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 5,
//     question:
//       "Kompanija u kojoj radim istražuje i ulazi na nova tržišta da bi povećala prihode.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 6,
//     question:
//       "Zadaci koji se dodjeljuju zaposlenima, za njih predstavljaju izazov, tokom kojeg mogu profesionalno da se razvijaju.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 7,
//     question:
//       "Kompanija ima operativne planove za izvršenje godišnjeg plana rada.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 8,
//     question:
//       "Mjesto na kojem zaposleni rade, opremljeno je svim pratećim materijalima, koji su neophodni za rad.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 9,
//     question:
//       "Kompanija kojoj pripadam intenzivno radi na sačuvavanju postojećih klijenata i stvaranju novih.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 10,
//     question:
//       "Moja kompanija posmatra zaposlene kao ključne za poslovni uspjeh preduzeća.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 11,
//     question:
//       "Kompanija u kojoj radim kontinuirano povećava prihode privlačenjem novih kupaca, kroz ponudu novih proizvoda.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 12,
//     question:
//       "Kompanija u kojoj radim preispituje efektivnost zaposlenih u svom poslovanju.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 13,
//     question:
//       "Tržište na kojem kompanija posluje, razvijeno je i odgovara svim kompanijskim zahtjevima.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 14,
//     question:
//       "Informacije koje zaposlenici dobijaju o svom poslu su tačne i precizne.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 15,
//     question: "Kompanija izvršava svoje finansijske obaveze na vrijeme.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 16,
//     question: "U kompaniji u kojoj radim, komunikacione barijere nema.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 17,
//     question:
//       "Kompanija vrši redovno planiranje budžeta i njegovo izvršenje po svim sektorima.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 18,
//     question: "Kompanija zaposlenima nudi profesionalno osposobljavanje.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 19,
//     question:
//       "Kompanija u kojoj radim detaljno istražuje rentabilne i nerentabilne kupce.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 20,
//     question:
//       "Kompanija kontinuirano povećava prihode privlačenjem novih kupaca, kroz ponudu novih proizvoda.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 21,
//     question: "Kompanija vrši analizu svojih konkurenata.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 22,
//     question:
//       "Kompanija kojoj pripadam intenzivno radi na motivaciji zaposlenih.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 23,
//     question:
//       "Kompanija posjeduje adekvatan IT sistem za praćenje i izvještavanje u poslovanju.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 24,
//     question:
//       "Kompanija vrši ABC analizu kupaca i kategoriše ih prema finansijskim vrijednostima.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 25,
//     question: "Kompanija preispituje efikasnost zaposlenih u svom radu.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 26,
//     question:
//       "Kompanija ima adekvatan sistem nagrađivanja u skladu sa uspostavljenim finansijskim ciljevima za sve zaposlene.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 27,
//     question:
//       "Okruženje u kojem zaposlenici rade ima sve neophodne klimatske i fizičke uslove (provjetrenost, optimalnu temperaturu, dovoljno svjetlosti...) koji omogućavaju potpunu produktivnost zaposlenih.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 28,
//     question:
//       "Kompanija je jasno definisala sektorske ciljeve na određenom (mjesečnom, kvartalnom, polugodišnjem ili godišnjem) nivou.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 29,
//     question:
//       "Sve informacije koje su mi potrebne za rad su transparentne i lako dostupne.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 30,
//     question: "Kompanija preispituje i vrednuje svoje dobavljače.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 31,
//     question: "Uprava mjeri zadvoljstvo zaposlenih u radu na godišnjem nivou.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 32,
//     question:
//       "Kompanija vrši analizu tržišta prije uvođenja novih proizvoda/usluga.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 33,
//     question:
//       "Moja kompanija izvršava analize „kako našu kompaniju posmatraju kupci“.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 34,
//     question:
//       "Kompanija vrši odgovorno i plansko investiranje u poslovnim poduhvatima.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 35,
//     question:
//       "Informacije koje su od visokog značaja za kompaniju, dijele se diskretno i odgovorno.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 36,
//     question:
//       "U kompaniji u kojoj radim racionalno se predviđaju i planiraju finansijski rizici.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 37,
//     question:
//       "Uprava redovno prati i mjeri postignute individualne ciljeve zaposlenih.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 38,
//     question: "Kompanija ima razvijen godišnji plan rada.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 39,
//     question:
//       "Sistem prenosa informacije od menadžmenta do zaposlenih je hijerarhijski i poštuje se.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
//   {
//     id: 40,
//     question:
//       "Moja kompanija objavljuje finansijske izvještaje koji pokazuju likvidnost preduzeća.",
//     options: [
//       "Potpuno se ne slažem",
//       "Djelimično se ne slažem",
//       "Nisam siguran/na",
//       "Djelimično se slažem",
//       "Potpuno se slažem",
//     ],
//     CorrectAns: "Potpuno se slažem",
//   },
// ];
export const questions = [
  {
    id: 1,
    question:
      "Kompanija u kojoj radim postavlja finansijsko-strateške ciljeve rasta i razvoja.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // CorrectAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 2,
    question:
      "Dijeljenje informacija u kompaniji u kojoj radim je pravovremeno i jasno.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 3,
    question:
      "Kompanija u kojoj radim može da ispuni sve zahtjeve njenog tržišta.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 4,
    question:
      "Ciljevi i radni zadaci, koji su postavljeni zaposlenicima, jasno su definisani i razumljivi.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 5,
    question:
      "Kompanija u kojoj radim istražuje i ulazi na nova tržišta da bi povećala prihode.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 6,
    question:
      "Zadaci koji se dodjeljuju zaposlenima, za njih predstavljaju izazov, tokom kojeg mogu profesionalno da se razvijaju.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 7,
    question:
      "Kompanija ima operativne planove za izvršenje godišnjeg plana rada.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 8,
    question:
      "Mjesto na kojem zaposleni rade, opremljeno je svim pratećim materijalima, koji su neophodni za rad.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 9,
    question:
      "Kompanija kojoj pripadam intenzivno radi na sačuvavanju postojećih klijenata i stvaranju novih.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 10,
    question:
      "Moja kompanija posmatra zaposlene kao ključne za poslovni uspjeh preduzeća.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 11,
    question:
      "Kompanija u kojoj radim kontinuirano povećava prihode privlačenjem novih kupaca, kroz ponudu novih proizvoda.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 12,
    question:
      "Kompanija u kojoj radim preispituje efektivnost zaposlenih u svom poslovanju.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 13,
    question:
      "Tržište na kojem kompanija posluje, razvijeno je i odgovara svim kompanijskim zahtjevima.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 14,
    question:
      "Informacije koje zaposlenici dobijaju o svom poslu su tačne i precizne.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 15,
    question: "Kompanija izvršava svoje finansijske obaveze na vrijeme.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 16,
    question: "U kompaniji u kojoj radim, komunikacione barijere nema.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 17,
    question:
      "Kompanija vrši redovno planiranje budžeta i njegovo izvršenje po svim sektorima.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 18,
    question: "Kompanija zaposlenima nudi profesionalno osposobljavanje.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 19,
    question:
      "Kompanija u kojoj radim detaljno istražuje rentabilne i nerentabilne kupce.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 20,
    question:
      "Kompanija kontinuirano povećava prihode privlačenjem novih kupaca, kroz ponudu novih proizvoda.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 21,
    question: "Kompanija vrši analizu svojih konkurenata.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 22,
    question:
      "Kompanija kojoj pripadam intenzivno radi na motivaciji zaposlenih.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 23,
    question:
      "Kompanija posjeduje adekvatan IT sistem za praćenje i izvještavanje u poslovanju.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 24,
    question:
      "Kompanija vrši ABC analizu kupaca i kategoriše ih prema finansijskim vrijednostima.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 25,
    question: "Kompanija preispituje efikasnost zaposlenih u svom radu.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 26,
    question:
      "Kompanija ima adekvatan sistem nagrađivanja u skladu sa uspostavljenim finansijskim ciljevima za sve zaposlene.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 27,
    question:
      "Okruženje u kojem zaposlenici rade ima sve neophodne klimatske i fizičke uslove (provjetrenost, optimalnu temperaturu, dovoljno svjetlosti...) koji omogućavaju potpunu produktivnost zaposlenih.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 28,
    question:
      "Kompanija je jasno definisala sektorske ciljeve na određenom (mjesečnom, kvartalnom, polugodišnjem ili godišnjem) nivou.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 29,
    question:
      "Sve informacije koje su mi potrebne za rad su transparentne i lako dostupne.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 30,
    question: "Kompanija preispituje i vrednuje svoje dobavljače.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 31,
    question: "Uprava mjeri zadvoljstvo zaposlenih u radu na godišnjem nivou.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 32,
    question:
      "Kompanija vrši analizu tržišta prije uvođenja novih proizvoda/usluga.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 33,
    question:
      "Moja kompanija izvršava analize „kako našu kompaniju posmatraju kupci“.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 34,
    question:
      "Kompanija vrši odgovorno i plansko investiranje u poslovnim poduhvatima.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 35,
    question:
      "Informacije koje su od visokog značaja za kompaniju, dijele se diskretno i odgovorno.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 36,
    question:
      "U kompaniji u kojoj radim racionalno se predviđaju i planiraju finansijski rizici.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 37,
    question:
      "Uprava redovno prati i mjeri postignute individualne ciljeve zaposlenih.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 38,
    question: "Kompanija ima razvijen godišnji plan rada.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 39,
    question:
      "Sistem prenosa informacije od menadžmenta do zaposlenih je hijerarhijski i poštuje se.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
  {
    id: 40,
    question:
      "Moja kompanija objavljuje finansijske izvještaje koji pokazuju likvidnost preduzeća.",
    options: [
      { question: "Potpuno se ne slažem", questId: 1, isActive: false },
      { question: "Djelimično se ne slažem", questId: 2, isActive: false },
      { question: "Nisam siguran/na", questId: 3, isActive: false },
      { question: "Djelimično se slažem", questId: 4, isActive: false },
      { question: "Potpuno se slažem", questId: 5, isActive: false },
    ],
    // correctAns: "Potpuno se slažem",
    correctAns: 5,
  },
];

export const finanseHealthOfTheCompany = [1, 7, 15, 17, 26, 28, 34, 36, 38, 40];
export const businessProcess = [2, 5, 8, 14, 16, 23, 27, 29, 35, 39];
export const efficensy = [4, 6, 10, 12, 18, 20, 22, 25, 31, 37];
export const customerSatisfaction = [3, 9, , 11, 13, 19, 21, 24, 30, 32, 33];
