import React from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Graph = ({ graphData, hidePrintBtn, onClick }) => {
  const labels = [
    "FINANSIJSKO ZDRAVLJE KOMPANIJE",
    "ADEKVATNOST POSLOVNIH PROCESA",
    "EFEKTIVNOST I EFIKASNOST ZAPOSLENIH",
    "ZADOVOLJSTVO KLIJENATA",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "Rezultat",
        data: graphData,
        tension: 0.4,
        borderColor: "rgb(182, 133, 255)",
        backgroundColor: "rgb(182, 133, 255)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        display: true,
        min: 0,
        max: 100,

        title: {
          display: true,
          text: "Procenat (%)",
          font: {
            size: 20,
            // family: "Arial",
            weight: "normal",
            style: "normal",
          },
        },
      },
    },

    responsive: true,

    plugins: {
      legend: {
        display: false,
        position: "top",
      },

      title: {
        display: true,
        text: "Rezultati:",
        font: {
          size: 35,
          weight: "normal",
          style: "italic",
        },
      },
    },
  };

  return (
    <div
      style={{
        maxWidth: "900px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2rem",
      }}
    >
      <button
        className="btn-download-pdf"
        style={{ visibility: `${hidePrintBtn ? "unset" : "hidden"}` }}
        onClick={onClick}
      >
        <span className="btn-download-pdf__text">Rezultat PDF</span>
        <span className="btn-download-pdf__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 35 35"
            id="bdd05811-e15d-428c-bb53-8661459f9307"
            data-name="Layer 2"
            className="svg"
          >
            <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path>
            <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path>
            <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path>
          </svg>
        </span>
      </button>
      <div style={{ alignSelf: "stretch" }}>
        <Line data={data} options={options}></Line>
      </div>
    </div>
  );
};

export default Graph;
