import { useEffect, useState } from "react";
import "./App.css";
import {
  QuestionsContainer,
  QuestionAnswerContainer,
  Graph,
} from "./Components";

import { questions } from "./assets/helperData/helperData";
import { filterData } from "./assets/helperFunctions/helperFunctions";
import {
  finanseHealthOfTheCompany,
  businessProcess,
  efficensy,
  customerSatisfaction,
} from "./assets/helperData/helperData";

function App() {
  const [next, setNext] = useState(0);
  const [question, setQuestion] = useState({ ...questions[0] });
  // const [score, setScore] = useState(0);
  // const [showScore, setShowScore] = useState(false);
  const [removeQuestions, setRemoveQuestions] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [hidePrintBtn, setHidePrintBtn] = useState(true);

  const [end, setEnd] = useState(false);
  const [resultsArr, setResultsArr] = useState([
    // { id: 0, grade: true },
    // { id: 1, grade: false },
    // { id: 2, grade: false },
    // { id: 3, grade: true },
    // { id: 4, grade: true },
    // { id: 5, grade: true },
    // { id: 6, grade: true },
    // { id: 7, grade: true },
    // { id: 8, grade: true },
    // { id: 9, grade: true },
    // { id: 10, grade: true },
    // { id: 11, grade: true },
    // { id: 12, grade: false },
    // { id: 13, grade: false },
    // { id: 14, grade: false },
    // { id: 15, grade: false },
    // { id: 16, grade: false },
    // { id: 17, grade: false },
    // { id: 18, grade: false },
    // { id: 19, grade: false },
    // { id: 20, grade: false },
    // { id: 21, grade: false },
    // { id: 22, grade: false },
    // { id: 23, grade: true },
    // { id: 24, grade: true },
    // { id: 25, grade: true },
    // { id: 26, grade: true },
    // { id: 27, grade: true },
    // { id: 28, grade: true },
    // { id: 29, grade: true },
    // { id: 30, grade: true },
    // { id: 31, grade: true },
    // { id: 32, grade: true },
    // { id: 33, grade: true },
    // { id: 34, grade: true },
    // { id: 35, grade: true },
    // { id: 36, grade: false },
    // { id: 37, grade: false },
    // { id: 38, grade: true },
    // { id: 39, grade: true },
    // { id: 40, grade: true },
  ]);

  // const temp, setTemp = useState(0)

  console.log(resultsArr);

  const nextButtonHandler = () => {
    setNext((prev) => prev + 1);
  };
  const prevButtonHandler = () => {
    setNext((prev) => prev - 1);
  };

  const getGrade = (grade) => {
    const gradeObj = {
      id: next + 1,
      grade,
    };
    setResultsArr((prev) => [...prev, gradeObj]);
    if (gradeObj.grade) {
      // setScore((prev) => prev + 10);
    }
  };

  const removeLastQuestion = () => {
    prevButtonHandler();
    setResultsArr((prev) => [...prev.slice(0, -1)]);
    // let curScore = score;
    // const checkIfCorrect = resultsArr.at(-1).grade;
    // if (checkIfCorrect) {
    //   curScore = curScore - 10;
    // } else {
    //   curScore = curScore;
    // }

    // setScore(() => curScore);
  };

  useEffect(() => {
    // console.log(next);
    if (next === 40) {
      // setQuestion(() => ({ ...questions[39] }));
      setRemoveQuestions(() => true);
      return;
    }
    setQuestion(() => ({ ...questions[next] }));
  }, [next]);

  useEffect(() => {
    if (next === 40) {
      setEnd(() => true);
    }
  }, [resultsArr]);

  useEffect(() => {
    const getPercent = (arr, questionLength = 10) => {
      const percentPoint = arr.reduce((acc, el) => {
        if (el.grade) {
          acc++;
        }
        return acc;
      }, 0);

      const percent = (percentPoint / questionLength) * 100;
      return percent;
    };

    const a = [];
    const b = [];
    const c = [];
    const d = [];
    if (end) {
      filterData(resultsArr, finanseHealthOfTheCompany, a);
      filterData(resultsArr, businessProcess, b);
      filterData(resultsArr, efficensy, c);
      filterData(resultsArr, customerSatisfaction, d);
      // setShowScore(() => true);
    }
    // console.log(resultsArr);

    const companyResults = [
      getPercent(a),
      getPercent(b),
      getPercent(c),
      getPercent(d),
    ];
    setGraphData(() => [...companyResults]);
    const endScore = getPercent(resultsArr, 40);
    // setScore(() => endScore);
  }, [end]);

  const printPDF = () => {
    setHidePrintBtn(() => false);
    const timer = setTimeout(() => {
      window.print();
      setHidePrintBtn(() => true);
      clearTimeout(timer);
    }, 500);
  };

  return (
    <div className="App">
      {!removeQuestions && (
        <div className="quizContainer">
          <QuestionsContainer nextQuestion={next} questionsArr={question} />
          <QuestionAnswerContainer
            questionsObj={question}
            onNextClick={nextButtonHandler}
            onPrevClick={removeLastQuestion}
            next={next}
            questions={questions}
            onGetGrade={getGrade}
          />
        </div>
      )}

      {removeQuestions && (
        <Graph
          graphData={graphData}
          onClick={printPDF}
          hidePrintBtn={hidePrintBtn}
        />
      )}
    </div>
  );
}

export default App;
